import AsyncContainer from "components/Containers/AsyncContainer";
import LoadingSpinner from "components/LoadingSpinner";
import PageContainer from "components/PageContainer/PageContainer";
import SignedInContainer from "components/PageContainer/SignedInContainer";
import SignedOutContainer from "components/PageContainer/SignedOutContainer";
import React, { lazy, LazyExoticComponent, MemoExoticComponent } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import type { RouteCondition, URLPaths } from "types/urls";
import URLs from "./URLConfig";
import { useServerStatusQuery } from "queries/serverQuery";
import Maintenance from "pages/Maintenance/Maintenance";

interface RouteType {
  path: URLPaths;
  component:
    | LazyExoticComponent<() => JSX.Element>
    | LazyExoticComponent<MemoExoticComponent<() => JSX.Element>>;
  routeCondition: RouteCondition;
  cssId: string;
}

// 코드 스플리팅을 위한 lazy import
const Main = lazy(() => import("pages/Main/Main"));
const SignIn = lazy(() => import("pages/SignIn/SignIn"));
const SignUp = lazy(() => import("pages/SignUp/SignUp"));
const UserInquiry = lazy(() => import("pages/UserInquiry/UserInquiry"));
const UserIdInquirySuccess = lazy(
  () => import("pages/UserInquiry/UserInquirySuccess/UserInquirySuccess"),
);
const ResetPassword = lazy(() => import("pages/UserInquiry/ResetPassword"));
const SearchResult = lazy(() => import("pages/SearchResult/SearchResult"));
const Payment = lazy(() => import("pages/Payment/Payment"));
const PaymentSuccess = lazy(() => import("pages/Payment/PaymentSuccess"));
const PaymentFailure = lazy(() => import("pages/Payment/PaymentFailure"));
const ProductDetail = lazy(() => import("pages/ProductDetail/ProductDetail"));
const Field = lazy(() => import("pages/Field/Field"));
const FieldDetail = lazy(() => import("pages/Field/FieldDetail/FieldDetail"));
const Partner = lazy(() => import("pages/Partner/Partner"));
const NewBook = lazy(() => import("pages/NewBook/NewBook"));
const RecommendedBook = lazy(
  () => import("pages/RecommendedBook/RecommendedBook"),
);
const RecommendedBookDetail = lazy(
  () =>
    import("pages/RecommendedBook/RecommendedBookDetail/RecommendedBookDetail"),
);
const EventPlanPage = lazy(
  () => import("pages/Event/EventPlanPage/EventPlanPage"),
);
const ModooMall = lazy(() => import("pages/ModooMall/ModooMall"));
const Support = lazy(() => import("pages/Support/Support"));
const Notice = lazy(() => import("pages/Support/Notice/Notice"));
const NoticeDetail = lazy(() => import("pages/Support/Notice/NoticeDetail"));
const Faq = lazy(() => import("pages/Support/Faq/Faq"));
const OneToOne = lazy(() => import("pages/Support/OneToOne/OneToOne"));
const OneToOneInquiry = lazy(
  () => import("pages/Support/OneToOne/OneToOneInquiry"),
);
const MyPage = lazy(() => import("pages/MyPage/MyPage"));
const MyPageMileageHistory = lazy(
  () => import("pages/MyPage/MileageHistory/MileageHistory"),
);
const CouponList = lazy(() => import("pages/MyPage/CouponList/CouponList"));
const EditProfile = lazy(() => import("pages/MyPage/EditProfile/EditProfile"));
const Widthdraw = lazy(() => import("pages/MyPage/Widthdraw/Widthdraw"));
const WidthdrawSuccess = lazy(
  () => import("pages/MyPage/Widthdraw/WidthdrawSuccess"),
);
const EventMainPage = lazy(
  () => import("pages/Event/EventMainPage/EventMainPage"),
);
const OrderHistory = lazy(
  () => import("pages/MyPage/OrderHistory/OrderHistory"),
);
const OrderHistoryDetail = lazy(
  () => import("pages/MyPage/OrderHistory/OrderHistoryDetail"),
);
const ReturnInquiry = lazy(() => import("pages/MyPage/Inquiry/ReturnInquiry"));
const ExchangeInquiry = lazy(
  () => import("pages/MyPage/Inquiry/ExchangeInquiry"),
);
const ShippingAddress = lazy(
  () => import("pages/MyPage/ShippingAddress/ShippingAddress"),
);
const LikeList = lazy(() => import("pages/MyPage/LikeList/LikeListMain"));
const Review = lazy(() => import("pages/MyPage/Review/Review"));
const Mall = lazy(() => import("pages/Mall/Mall"));
const MallCommunity = lazy(() => import("pages/MallCommunity/MallCommunity"));
const MallCommunityDetail = lazy(
  () => import("pages/MallCommunityDetail/MallCommunityDetail"),
);
const UnivBook = lazy(() => import("pages/UnivBook/UnivBook")); // 구내서점
const UnivGoods = lazy(() => import("pages/UnivBook/UnivGoods"));

const InvalidApproach = lazy(() => import("pages/InvalidApproach"));
const ConfirmPassword = lazy(() => import("pages/ConfirmPassword"));
const AboutModoo = lazy(() => import("pages/AboutModoo/AboutModoo"));
const CartView = lazy(() => import("pages/CartView/CartView"));
const PostAuth = lazy(() => import("pages/SignUp/PostAuth"));
const SignUpSuccess = lazy(() => import("pages/SignUp/SignUpSuccess"));
const PostSNSSignIn = lazy(() => import("pages/SignIn/PostSNSSignIn"));
const MallAlias = lazy(() => import("pages/Mall/MallAlias"));

export const routes: RouteType[] = [
  {
    path: URLs.Main,
    component: Main,
    routeCondition: "WHENEVER",
    cssId: "main",
  },
  {
    path: URLs.SignIn,
    component: SignIn,
    routeCondition: "MUST_BE_SIGNED_OUT",
    cssId: "sign-in",
  },
  {
    path: URLs.PostSNSSignIn,
    component: PostSNSSignIn,
    routeCondition: "WHENEVER",
    cssId: "sign-in",
  },
  {
    path: URLs.SignUp,
    component: SignUp,
    routeCondition: "MUST_BE_SIGNED_OUT",
    cssId: "SignUp",
  },
  {
    path: URLs.SignUpSuccess,
    component: SignUpSuccess,
    routeCondition: "MUST_BE_SIGNED_OUT",
    cssId: "SignUp",
  },
  {
    path: URLs.UserInquiry,
    component: UserInquiry,
    routeCondition: "MUST_BE_SIGNED_OUT",
    cssId: "UserInquiry",
  },
  {
    path: URLs.UserIdInquirySuccess,
    component: UserIdInquirySuccess,
    routeCondition: "MUST_BE_SIGNED_OUT",
    cssId: "UserInquirySucccess",
  },
  {
    path: URLs.ResetPassword,
    component: ResetPassword,
    routeCondition: "MUST_BE_SIGNED_OUT",
    cssId: "UserInquiry",
  },
  {
    path: URLs.SearchResult,
    component: SearchResult,
    routeCondition: "WHENEVER",
    cssId: "SearchResult",
  },
  {
    path: URLs.Payment,
    component: Payment,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "Payment",
  },
  {
    path: URLs.PaymentSuccess,
    component: PaymentSuccess,
    routeCondition: "MUST_BE_SIGNED_IN",
    // routeCondition: "WHENEVER",
    cssId: "PaymentResult",
  },
  {
    path: URLs.PaymentFailure,
    component: PaymentFailure,
    routeCondition: "MUST_BE_SIGNED_IN",
    // routeCondition: "WHENEVER",
    cssId: "PaymentResult",
  },
  {
    path: URLs.ProductDetail,
    component: ProductDetail,
    routeCondition: "WHENEVER",
    cssId: "ProductDetail",
  },
  {
    path: URLs.Field,
    component: Field,
    routeCondition: "WHENEVER",
    cssId: "field-page",
  },
  {
    path: URLs.FieldDetail,
    component: FieldDetail,
    routeCondition: "WHENEVER",
    cssId: "FieldDetail",
  },
  {
    path: URLs.Partner,
    component: Partner,
    routeCondition: "WHENEVER",
    cssId: "Partner",
  },
  {
    path: URLs.NewBook,
    component: NewBook,
    routeCondition: "WHENEVER",
    cssId: "NewBook",
  },
  {
    path: URLs.RecommendedBook,
    component: RecommendedBook,
    routeCondition: "WHENEVER",
    cssId: "RecommendedBook",
  },
  {
    path: URLs.RecommendedBookDetail,
    component: RecommendedBookDetail,
    routeCondition: "WHENEVER",
    cssId: "RecommendedBookDetail",
  },
  {
    path: URLs.EventMainPage,
    component: EventMainPage,
    routeCondition: "WHENEVER",
    cssId: "event-main-page",
  },
  {
    path: URLs.EventPlanPage,
    component: EventPlanPage,
    routeCondition: "WHENEVER",
    cssId: "event-plan-page",
  },
  {
    path: URLs.ModooMall,
    component: ModooMall,
    routeCondition: "WHENEVER",
    cssId: "ModooMall",
  },
  {
    path: URLs.Support,
    component: Support,
    routeCondition: "WHENEVER",
    cssId: "Support",
  },
  {
    path: URLs.Notice,
    component: Notice,
    routeCondition: "WHENEVER",
    cssId: "Support",
  },
  {
    path: URLs.NoticeDetail,
    component: NoticeDetail,
    routeCondition: "WHENEVER",
    cssId: "Support",
  },
  {
    path: URLs.Faq,
    component: Faq,
    routeCondition: "WHENEVER",
    cssId: "Support",
  },
  {
    path: URLs.OneToOne,
    component: OneToOne,
    routeCondition: "MUST_BE_SIGNED_IN",
    // routeCondition: "WHENEVER",
    cssId: "Support",
  },
  {
    path: URLs.OneToOneInquiry,
    component: OneToOneInquiry,
    routeCondition: "MUST_BE_SIGNED_IN",
    // routeCondition: "WHENEVER",
    cssId: "Support",
  },
  {
    path: URLs.MyPage,
    component: MyPage,
    routeCondition: "MUST_BE_SIGNED_IN",
    // routeCondition: "WHENEVER",
    cssId: "my-page",
  },
  {
    path: URLs.MileageHistory,
    component: MyPageMileageHistory,
    routeCondition: "MUST_BE_SIGNED_IN",
    // routeCondition: "WHENEVER",
    cssId: "my-page-mileage",
  },
  {
    path: URLs.CouponList,
    component: CouponList,
    routeCondition: "MUST_BE_SIGNED_IN",
    // routeCondition: "WHENEVER",
    cssId: "CouponList",
  },
  {
    path: URLs.EditProfile,
    component: EditProfile,
    routeCondition: "MUST_BE_SIGNED_IN",
    // routeCondition: "WHENEVER",
    cssId: "EditProfile",
  },
  {
    path: URLs.Widthdraw,
    component: Widthdraw,
    routeCondition: "MUST_BE_SIGNED_IN",
    // routeCondition: "WHENEVER",
    cssId: "Widthdraw",
  },
  {
    path: URLs.WidthdrawSuccess,
    component: WidthdrawSuccess,
    routeCondition: "MUST_BE_SIGNED_IN",
    // routeCondition: "WHENEVER",
    cssId: "Widthdraw",
  },
  {
    path: URLs.OrderHistory,
    component: OrderHistory,
    routeCondition: "MUST_BE_SIGNED_IN",
    // routeCondition: "WHENEVER",
    cssId: "OrderHistory",
  },
  {
    path: URLs.OrderHistoryDetail,
    component: OrderHistoryDetail,
    routeCondition: "MUST_BE_SIGNED_IN",
    // routeCondition: "WHENEVER",
    cssId: "OrderHistory",
  },
  {
    path: URLs.ReturnInquiry,
    component: ReturnInquiry,
    routeCondition: "MUST_BE_SIGNED_IN",
    // routeCondition: "WHENEVER",
    cssId: "Inquiry",
  },
  {
    path: URLs.ExchangeInquiry,
    component: ExchangeInquiry,
    routeCondition: "MUST_BE_SIGNED_IN",
    // routeCondition: "WHENEVER",
    cssId: "Inquiry",
  },
  {
    path: URLs.ShippingAddress,
    component: ShippingAddress,
    routeCondition: "MUST_BE_SIGNED_IN",
    // routeCondition: "WHENEVER",
    cssId: "ShippingAddress",
  },
  {
    path: URLs.LikeList,
    component: LikeList,
    routeCondition: "MUST_BE_SIGNED_IN",
    // routeCondition: "WHENEVER",
    cssId: "LikeListMain",
  },
  {
    path: URLs.Review,
    component: Review,
    routeCondition: "MUST_BE_SIGNED_IN",
    // routeCondition: "WHENEVER",
    cssId: "Review",
  },
  {
    path: URLs.Mall,
    component: Mall,
    routeCondition: "WHENEVER",
    cssId: "Mall",
  },
  {
    path: URLs.MallCommunity,
    component: MallCommunity,
    routeCondition: "WHENEVER",
    cssId: "MallCommunity",
  },
  {
    path: URLs.MallCommunityDetail,
    component: MallCommunityDetail,
    routeCondition: "WHENEVER",
    cssId: "MallCommunity",
  },
  {
    path: URLs.UnivBook,
    component: UnivBook,
    routeCondition: "WHENEVER",
    cssId: "UnivBook",
  },
  {
    path: URLs.UnivGoods,
    component: UnivGoods,
    routeCondition: "WHENEVER",
    cssId: "UnivGoods",
  },
  {
    path: URLs.AboutModoo,
    component: AboutModoo,
    routeCondition: "WHENEVER",
    cssId: "AboutModoo",
  },
  {
    path: URLs.CartView,
    component: CartView,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "CartView",
  },
  {
    path: URLs.PostAuth,
    component: PostAuth,
    routeCondition: "WHENEVER",
    cssId: "PostAuth",
  },
  {
    path: URLs.MallAlias,
    component: MallAlias,
    routeCondition: "WHENEVER",
    cssId: "MallAlias",
  },
  // {
  //   path: URLs.ConfirmPassword,
  //   component: ConfirmPassword,
  //   routeCondition: "WHENEVER",
  //   cssId: "ConfirmPassword",
  // },
];

const PageRoutes = () => {
  const { isMaintaining } = useServerStatusQuery();
  return (
    <Router>
      <AsyncContainer suspenseFallback={<LoadingSpinner />}>
        <Routes>
          {isMaintaining ? (
            <Route
              path="*"
              element={
                <PageContainer id="maintenance">
                  <Maintenance />
                </PageContainer>
              }
            />
          ) : (
            <>
              {routes.map((route, index) => {
                const {
                  path,
                  component: Component,
                  routeCondition,
                  cssId: id,
                } = route;
                switch (routeCondition) {
                  case "WHENEVER":
                    return (
                      <Route
                        key={index}
                        path={path}
                        element={
                          <PageContainer id={id}>
                            <Component />
                          </PageContainer>
                        }
                      />
                    );
                  case "MUST_BE_SIGNED_IN":
                    return (
                      <Route
                        key={index}
                        path={path}
                        element={
                          <SignedInContainer>
                            <PageContainer id={id}>
                              <Component />
                            </PageContainer>
                          </SignedInContainer>
                        }
                      />
                    );
                  case "MUST_BE_SIGNED_OUT":
                    return (
                      <Route
                        key={index}
                        path={path}
                        element={
                          <SignedOutContainer>
                            <PageContainer id={id}>
                              <Component />
                            </PageContainer>
                          </SignedOutContainer>
                        }
                      />
                    );
                }
              })}
              <Route path="*" element={<InvalidApproach />} />
            </>
          )}
        </Routes>
      </AsyncContainer>
    </Router>
  );
};

export default PageRoutes;
