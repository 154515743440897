import { axiosInstance } from "config/axiosConfig";
import dayjs from "dayjs";
import { updateReviewType } from "types/book";

export const getSearchResult = (searchParam: URLSearchParams) => async () => {
  const response = await axiosInstance.get("/book/getListBook", {
    params: searchParam,
  });
  return response.data;
};

export const getBookReviewData = (bookCd: string, page: number) => async () => {
  const response = await axiosInstance.get("/review/getListReview", {
    params: {
      rows: 6,
      start: (page - 1) * 6,
      productSeq: bookCd,
    },
  });
  return response.data;
};

export const getBookDetail =
  (
    bookCd: string,
    sort?: "recent" | "title",
    direction?: "asc" | "desc",
    shopSeq?: string,
  ) =>
  async () => {
    const params: Record<string, unknown> = {
      bookCd,
      rows: bookCd.split(";").length,
      sortRecent: null,
      sortBookTitle: null,
      shopSeq,
    };
    if (sort == "recent") {
      params.sortRecent = direction;
    }
    if (sort == "title") {
      params.sortBookTitle = direction;
    }

    const response = await axiosInstance.get("/book/getListBook", {
      params,
    });
    return response.data;
  };

export const getNewBookList =
  (selectedMenu: string, startDate?: string, endDate?: string) => async () => {
    const response = await axiosInstance.get("/book/getListBook", {
      params: {
        rows: 18,
        categoryId: selectedMenu == "전체" ? null : selectedMenu,
        startDate: startDate ?? null,
        endDate: endDate ?? null,
        sortRecent: "desc",
      },
    });
    return response.data;
  };

export const getNewBookListWithSearchParams =
  (seearchParam: URLSearchParams) => async () => {
    const response = await axiosInstance.get("/book/getListBook", {
      params: seearchParam,
    });
    return response.data;
  };

export const getBookReview = (bookSeq: string) => async () => {
  const response = await axiosInstance.get("/review/getReview", {
    params: {
      bookSeq,
    },
  });
  return response.data;
};

export const getListRcmBookMenu = () => async () => {
  const response = await axiosInstance.get("/book/getListRcmBookMenu");
  return response.data;
};

export const getListRcmBook = (seearchParam: URLSearchParams) => async () => {
  const response = await axiosInstance.get("/book/getListRcmBook", {
    params: seearchParam,
  });
  return response.data;
};

// 분야별 도서 상세 > 이분야 신간
export const getFieldClassNewBookList =
  (selectedMenu: string, startDate?: string, endDate?: string) => async () => {
    const response = await axiosInstance.get("/book/getListBook", {
      params: {
        categoryId: selectedMenu == "전체" ? null : selectedMenu,
        startDate: startDate ?? null,
        endDate: endDate ?? null,
      },
    });
    return response.data;
  };

export const getFieldBookList = (seearchParam: URLSearchParams) => async () => {
  const response = await axiosInstance.get("/book/getListBook", {
    params: seearchParam,
  });
  return response.data;
};

/** Review List */
export const getReviewList = (userId: string) => async () => {
  const response = await axiosInstance.get("/review/getListReview", {
    params: {
      userId: userId,
    },
  });
  return response.data;
};

/** Delete Review */
export const deleteReview = async (reviewSeq: string) => {
  const response = await axiosInstance.post(
    `/review/deleteReview?reviewSeq=${reviewSeq}`,
  );
  return response.data;
};

export const updateReview = async (req: updateReviewType) => {
  const response = await axiosInstance.post(
    `/review/updateReview?content=${req.content}&rated=${req.rated}&reviewSeq=${req.reviewSeq}`,
  );

  return response.data;
};

export const getField = async () => {
  const response = await axiosInstance.get("/book/getListBookCatMenu", {
    params: {
      categoryDepth: 1,
    },
  });
  return response.data;
};

export const getSingularBookList =
  (start: number, onelineGb: string) => async () => {
    const response = await axiosInstance.get("/book/getListBook", {
      params: {
        rows: 6,
        start: start,
        onelineGb: onelineGb,
        startDate: dayjs().subtract(90, "day").format("YYYYMMDD"),
        endDate: dayjs().format("YYYYMMDD"),
        sortRandom: `desc`,
      },
    });
    return response.data;
  };

export const getPartnerBookList =
  (seearchParam: URLSearchParams) => async () => {
    const response = await axiosInstance.get("/book/getListBook", {
      params: seearchParam,
    });
    return response.data;
  };

export const getReviewRating =
  (productSeq = "") =>
  async () => {
    const response = await axiosInstance.get("/review/countReviewToRate", {
      params: {
        productSeq,
      },
    });
    return response.data;
  };

export const getRelatedEvent =
  (bookCd = "") =>
  async () => {
    const response = await axiosInstance.get("/book/getRelatedEvent", {
      params: {
        bookCd,
      },
    });
    return response.data;
  };

export const getRcmYearList = (catCd: string) => async () => {
  const response = await axiosInstance.get("/book/getListRcmBookYear", {
    params: {
      catCd,
    },
  });
  return response.data;
};

// 대학 구내서점 전공도서 메뉴 조회
export const getUnivBookMenuList = async (shopSeq: string) => {
  const response = await axiosInstance.get("/book/getListUnivBookMenu", {
    params: {
      shopSeq,
    },
  });

  return response.data;
};

export const getUnivBookList = (seearchParam: URLSearchParams) => async () => {
  const response = await axiosInstance.get("book/getListUnivBook", {
    params: seearchParam,
  });
  return response.data;
};
