import { useMutation, useQuery } from "@tanstack/react-query";
import {
  getBookDetail,
  getField,
  getFieldClassNewBookList,
  getListRcmBookMenu,
  getNewBookList,
  getRcmYearList,
  getRelatedEvent,
  getReviewRating,
  getUnivBookMenuList,
} from "api/bookApi";
import { deleteBkmr } from "api/userApi";
import queryKey, {
  bookDetailKey,
  newBookKey,
  rcmMenuListKey,
} from "constants/queryKey";
import { useRecentBook } from "hooks/useRecentBook";
import _ from "lodash";
import { useReviewQuery } from "pages/ProductDetail/useReviewQuery";
import { useEffect, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setMenuStore, useMenuStore } from "stores/menuStore";
import { ResBaseVo } from "types/api";
import {
  Book,
  Field,
  Menu,
  Rating,
  RcmBookMenuList,
  UnivBookMenuListVo,
} from "types/book";
import { fillBookDummyData, makeBookData } from "utils/commonUtils";
import { useEventListQuery } from "./eventQuery";
import {
  setUnivBookMenuStore,
  useUnivBookMenuStore,
} from "stores/univBookMenuStore";
import { usePageClassification } from "hooks/usePageClassification";

export const useNewBookQuery = (
  selectedMenu = "전체",
  startDate?: string,
  endDate?: string,
) => {
  const menuList = useMenuStore();
  const { data } = useQuery<ResBaseVo<{ bookList: Book[] }>>(
    newBookKey(selectedMenu, startDate, endDate),
    getNewBookList(
      menuList.find((menu) => menu.categoryName == selectedMenu)?.categoryId ??
        "",
      startDate,
      endDate,
    ),
  );

  const bookData: Book[] = useMemo(
    () => fillBookDummyData(data?.data.bookList ?? []),
    [data?.data.bookList],
  );

  return { bookData };
};

export const useBookDetailQuery = () => {
  const { currentShopSeq } = usePageClassification();

  const [searchParams] = useSearchParams();
  const bookCd = searchParams.get("bookCd");
  const { handleRecentBookInsert } = useRecentBook();

  const { data } = useQuery<
    ResBaseVo<{ bookList: Book[]; solrVo: { numFound: number } }>
  >(
    bookDetailKey(`${bookCd}`, undefined, undefined, currentShopSeq),
    getBookDetail(`${bookCd}`, undefined, undefined, currentShopSeq),
    {
      enabled: !!bookCd && `${bookCd}`.length > 5,
      suspense: true,
    },
  );

  // 책 데이터가 날아갔어요 ㅜㅜ
  if (data && data.data.solrVo.numFound === 0) {
    // 처리는 에러바운더리 단에서 진행하자.
    throw new Error();
  }

  // 변형이 필요없는 데이터는 useState 및 useEffect를 사용하지 않아도 된다.
  const bookData = useMemo(() => {
    const book = data?.data?.bookList?.[0];
    if (!book) return makeBookData(1)[0];
    return fillBookDummyData([book])[0];
  }, [data?.data?.bookList]);

  // 리액트 외부의 시스템(로컬스토리지)과의 연동은 effect를 통해서 진행한다.
  useEffect(() => {
    if (!data?.data?.bookList?.[0]) return;
    handleRecentBookInsert(data?.data?.bookList?.[0]);
  }, [data?.data?.bookList]);

  return { bookData };
};

export const useBookListWithBookCdQuery = (
  bookCd: string,
  option?: {
    viewYn?: "Y" | "N";
    sort?: "recent" | "title";
    direction?: "asc" | "desc";
  },
  shopSeq?: string,
) => {
  const viewYn = option?.viewYn;
  const sort = option?.sort;
  const direction = option?.direction;

  const { data, refetch } = useQuery<ResBaseVo<{ bookList: Book[] }>>(
    bookDetailKey(`${bookCd}`, sort, direction, shopSeq),
    getBookDetail(`${bookCd}`, sort, direction, shopSeq),
    {
      enabled:
        !!bookCd &&
        `${bookCd}`.length > 5 &&
        (!!viewYn || (viewYn && viewYn == "Y")), // viewYn이 있으면서 Y인 경우
    },
  );

  const bookList = useMemo(
    () => fillBookDummyData(data?.data.bookList ?? []),
    [data?.data.bookList],
  );

  return { bookList, refetch };
};

export const useRcmBookMenuListQuery = () => {
  const { data } = useQuery<ResBaseVo<{ rcmBookMenuList: RcmBookMenuList[] }>>(
    rcmMenuListKey(),
    getListRcmBookMenu(),
  );

  const [
    rcmBookMenuList,
    mainRcmBookMenuList,
    subRcmBookMenuList,
  ]: RcmBookMenuList[][] = useMemo(() => {
    const res = data?.data.rcmBookMenuList;
    if (!res) return [[], [], []];
    return [
      res,
      _.filter(res, { menuLvl: "2" }),
      _.filter(res, { menuLvl: "3" }),
    ];
  }, [data?.data.rcmBookMenuList]);

  return { rcmBookMenuList, mainRcmBookMenuList, subRcmBookMenuList };
};

export const useFieldClassNewBookQuery = (
  selectedMenu = "전체",
  selectedCatId: string,
  startDate?: string,
  endDate?: string,
) => {
  const { data } = useQuery<ResBaseVo<{ bookList: Book[] }>>(
    newBookKey(selectedCatId, startDate, endDate),
    getFieldClassNewBookList(selectedCatId, startDate, endDate),
  );

  const bookData = useMemo(
    () => fillBookDummyData(data?.data.bookList ?? []),
    [data?.data.bookList],
  );

  return { bookData };
};

export const useDelBkmrQuery = () => {
  const navigate = useNavigate();
  const refresh = () => {
    navigate(0);
  };
  const mutation = useMutation(deleteBkmr, {
    useErrorBoundary: false,
    onSuccess: () => {
      alert("삭제되었습니다.");
      refresh();
    },
    onError: (error: {
      code?: string;
      status?: number;
      data: Record<string, unknown>;
    }) => {
      alert(error.data.resultMsg);
    },
  });

  return {
    ...mutation,
  };
};

export const useFieldQuery = () => {
  const menuList = useMenuStore();
  const { data: queryData } = useQuery<ResBaseVo<{ bookMenuList: Field[] }>>(
    [queryKey.MENU],
    getField,
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      suspense: true,
      onSuccess: (res) => {
        const bookMenuList = res.data.bookMenuList;
        const menuLength = bookMenuList.length;
        const newMenuList: Menu[] = [];
        for (let i = 0; i < menuLength; i++) {
          const menu = bookMenuList[i];
          if (menu.parentCategoryId == "000")
            newMenuList.push({ ...menu, childMenu: [] });
          else {
            const parentMenuIndex = newMenuList.findIndex(
              (newMenu) => newMenu.categoryId == menu.parentCategoryId,
            );
            if (parentMenuIndex < 0) continue;
            newMenuList[parentMenuIndex].childMenu.push({ ...menu });
          }
        }
        setMenuStore((prev) => [prev[0], ...newMenuList]);
      },
    },
  );
  const queryMenuList = queryData?.data.bookMenuList ?? [];

  return { menuList, queryMenuList };
};

export const useReviewRatingQuery = () => {
  const [searchParam] = useSearchParams();
  const productSeq = searchParam.get("bookCd") ?? "";
  const { reviewCount } = useReviewQuery(1);
  const { data } = useQuery<ResBaseVo<{ reviewRateCnt: Rating[] }>>(
    ["REVIEW", "RATING", productSeq],
    getReviewRating(productSeq),
    {
      suspense: true,
      enabled: !!productSeq,
    },
  );
  const { ratingData, rating } = useMemo(() => {
    let rating = 0;
    const ratingData: Rating[] = [
      {
        rated: 1,
        count: 0,
      },
      {
        rated: 2,
        count: 0,
      },
      {
        rated: 3,
        count: 0,
      },
      {
        rated: 4,
        count: 0,
      },
      {
        rated: 5,
        count: 0,
      },
    ];
    if (reviewCount == 0)
      return {
        ratingData,
        rating,
      };

    if (!data || data.data.reviewRateCnt.length == 0)
      return {
        ratingData,
        rating,
      };
    data.data.reviewRateCnt.forEach((rate) => {
      const idx = rate.rated - 1;
      ratingData[idx].count = rate.count;
    });
    rating =
      ratingData.reduce(
        (prev, rating) => prev + rating.count * rating.rated,
        0,
      ) / reviewCount;

    return {
      ratingData,
      rating,
    };
  }, [reviewCount, data]);

  return { ratingData, rating };
};

export const useRelatedEventQuery = () => {
  const [searchParams] = useSearchParams();
  const bookCd = searchParams.get("bookCd") ?? "";
  const { data } = useQuery<ResBaseVo<{ relatedEvent: string[] }>>(
    ["RELATED_EVENT", bookCd],
    getRelatedEvent(bookCd),
    {
      enabled: bookCd.length > 0,
      suspense: true,
    },
  );
  const { eventData } = useEventListQuery();

  const relatedEvent = useMemo(() => {
    const relatedEventSeqArr = data?.data?.relatedEvent ?? [];
    if (relatedEventSeqArr.length == 0) return;
    const relatedEventArr = eventData.filter((evt) =>
      relatedEventSeqArr.includes(evt.eventSeq),
    );
    if (relatedEventArr.length == 0) return;
    const shuffledArr = _.shuffle(relatedEventArr);
    return {
      ...shuffledArr[0],
      eventTypNm:
        shuffledArr[0].eventTypCd == "X"
          ? ("기획전" as const)
          : shuffledArr[0].eventTypCd == "E"
          ? ("이벤트" as const)
          : ("기획전" as const),
    };
  }, [data?.data?.relatedEvent, eventData]);

  return { relatedEvent };
};

export const useRcmYearListQuery = () => {
  const [searchParams] = useSearchParams();
  const catCd = searchParams.get("catCd") ?? "";
  const { data } = useQuery<ResBaseVo<{ rcmBookYearList: string[] }>>(
    ["YEAR_LIST", catCd],
    getRcmYearList(catCd),
    {
      suspense: true,
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: catCd.length > 0,
    },
  );
  const yearList = useMemo(
    () => data?.data.rcmBookYearList ?? [],
    [data?.data.rcmBookYearList],
  );

  return { yearList };
};

// 대학 구내서점 전공도서 메뉴 목록 조회 쿼리
export const useUnivBookMenuListQuery = (shopSeq: string) => {
  const univBookMenuList = useUnivBookMenuStore();

  useQuery<ResBaseVo<{ univBookMenuList: UnivBookMenuListVo[] }>>({
    queryKey: ["UNIV_BOOK_MENU_LIST", shopSeq],
    queryFn: () => getUnivBookMenuList(shopSeq),
    enabled: !!shopSeq,
    onSuccess: (res) => {
      setUnivBookMenuStore(res.data.univBookMenuList);
    },
  });

  return { univBookMenuList };
};
